import React, { Component } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import soluciones1 from './images/soluciones1.png';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';

class Solutions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,

            displayText: "",
            language: this.props.language
        }

        this.changeSection = this.changeSection.bind(this);
        this.ref = React.createRef();
    }

    changeSection() {
        this.props.changeCurrentSection("Solutions");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Solutions", page: '/Solutions' });
    }

    componentDidMount() {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    this.setState({ isVisible: entry.isIntersecting });
                });
            },
            { threshold: 0.1 }
        );
        observer.observe(this.ref.current);
    }

    render() {
        if (this.state.language != this.props.language)
            this.setState({ language: this.props.language })

        return (
            <section id="soluciones" class="section-coowise" onMouseOver={this.props.currentSection != "Solutions" ? this.changeSection : () => { }}>
                <Container>
                    <Row className='general-row'>
                        <div className='general-div'>
                            <hr class="line-title"></hr>
                            <h2 class="title-section">{this.props.language == "ES" ? "Soluciones" : "Solutions"}</h2>
                        </div>
                    </Row>
                    <Row className={"row-soluciones" + (this.state.isVisible ? " visible" : "")} ref={this.ref}>
                        <Col xs={12} lg={6}>
                            <img src={soluciones1} class="img-soluciones" />
                        </Col>
                        <Col className="col-right" xs={12} lg={6}>
                            <div class="div-description-solution">
                                <h2 class="subtitle-coowise">Tender Notify</h2>
                                <p class="paragraph-coowise">
                                    {
                                        this.props.language == "ES"
                                            ? "Una plataforma que permite a cientos de usuarios explorar datos de mercado mediante el uso de capacidades de IA, informes, notificaciones, conectividad API. Haciendo de esta app el mejor aliado para el crecimiento de tu negocio."
                                            : "A platform that allows hundreds of users to explore market data by using AI capabilities, reporting, notifications, API connectivity. Making this app the best ally for the growth of your business."
                                    }
                                </p>
                            </div>

                            {
                                this.props.fetchedGetWebCoowiseUrls && this.props.getWebCoowiseUrlsDTO.webTendersUrl != null
                                    ?
                                    <Button
                                        className={"button-secondary-coowise margin visible"}
                                        href={this.props.getWebCoowiseUrlsDTO.webTendersUrl}
                                        target="_blank"
                                    >
                                        {this.props.language == "ES" ? "IR AL SITIO WEB" : "GO TO THE WEB"}
                                    </Button>
                                    :
                                    <Button
                                        className={"button-secondary-coowise margin visible"}
                                        onClick={this.props.changeErrorModalFlag}>
                                        {this.props.language == "ES" ? "IR AL SITIO WEB" : "GO TO THE WEB"}
                                    </Button>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

Solutions.propTypes = {
    language: PropTypes.string.isRequired,
    changeErrorModalFlag: PropTypes.func.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

const mapStateToProps = (value) => {
    return {
        fetchingGetWebCoowiseUrls: value.general.fetchingGetWebCoowiseUrls,
        fetchedGetWebCoowiseUrls: value.general.fetchedGetWebCoowiseUrls,
        getWebCoowiseUrlsError: value.general.getWebCoowiseUrlsError,
        getWebCoowiseUrlsDTO: value.general.getWebCoowiseUrlsDTO
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Solutions);