import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from './images/logo-coowise.svg'
import { connect } from "react-redux";
import {
    changeLanguage,
    changeCurrentSection
} from "../actions/generalActions";
import spainFlag from './images/Flag_Spain.svg';
import united_Kingdom from './images/Flag_United_Kingdom.svg';
import ReactGA4 from 'react-ga4';
import { Link } from 'react-router-dom';

class NavbarWeb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            legalActiveItem: ''
        }

        this.changeLanguage = this.changeLanguage.bind(this);
        this.onClickLegalLink = this.onClickLegalLink.bind(this);
        this.ref = React.createRef();
    }

    onClickLink(id, section) {
        if (this.props.currentSection != section) {
            this.props.changeCurrentSection(section);
            if (this.props.cookieUp)
                ReactGA4.send({ hitType: "pageview", title: section, page: ('/'+ section) });
        }

        let element = document.getElementById(id);
        if (element != null){
            let windowWidth = window.innerWidth;
            if(windowWidth > 991)
                element.scrollIntoView();
            else{
                element.scrollIntoView();
                window.scrollBy(0, -50);
            }
        }

        let collapse = document.getElementById("responsive-navbar-nav")

        if(collapse != null && collapse.className === "navbar-collapse collapse show" && this.ref.current != null)
            this.ref.current.click();
    }

    changeLanguage(language) {
        if (this.props.cookieUp)
            ReactGA4.gtag('event', 'click', {
                'event_category': 'User',
                'event_label': 'Click on Language (' + language + ')',
                'value': 'Click on Language (' + language + ')'
            });

        this.props.changeLanguage(language);
    }

    onClickLegalLink(legalActiveItem) {
        this.setState({ legalActiveItem: legalActiveItem });

        let element = document.getElementById("privacy-section");
        if (element != null)
            element.scrollIntoView({ behavior: "smooth" });
        
        let collapse = document.getElementById("responsive-navbar-nav")

        if (collapse != null && collapse.className === "navbar-collapse collapse show" && this.ref.current != null)
            this.ref.current.click();
    }

    componentDidMount(){
        if(window.location.pathname == "/cookies-policy")
            this.setState({ legalActiveItem: "/cookies-policy" });
        else
        if(window.location.pathname == "/privacy-policy")
            this.setState({ legalActiveItem: "/privacy-policy" });
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" className="navCoowise navbar-static-top">
                <Navbar.Brand 
                    onClick=
                    {
                        window.location.pathname != "/cookies-policy" && window.location.pathname != "/privacy-policy"
                        ? () => this.onClickLink("header")
                        : () => {window.location.pathname = "/"}
                    }
                    >
                    <img className="cursor" src={logo} alt="logo-coowise" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" id="navbar-toggle" ref={this.ref}/>
                <Navbar.Collapse id="responsive-navbar-nav">

                    <Nav className="mr-auto nav-coowise">
                        {
                            window.location.pathname != "/cookies-policy" && window.location.pathname != "/privacy-policy"
                                ?
                                <>
                                    <Nav.Link data-toggle="collapse" onClick={() => this.onClickLink("header", "Home")}>{this.props.language == "ES" ? "INICIO" : "HOME"}</Nav.Link>
                                    <Nav.Link onClick={() => this.onClickLink("servicios", "Services")}>{this.props.language == "ES" ? "SERVICIOS" : "SERVICES"}</Nav.Link>
                                    <Nav.Link onClick={() => this.onClickLink("soluciones", "Solutions")}>{this.props.language == "ES" ? "SOLUCIONES" : "SOLUTIONS"}</Nav.Link>
                                    <Nav.Link onClick={() => this.onClickLink("contacto", "Contact")}>{this.props.language == "ES" ? "CONTACTO" : "CONTACT"}</Nav.Link>
                                </>
                                :
                                <>
                                    <Link
                                        to="/privacy-policy"
                                        onClick={() => this.onClickLegalLink("/privacy-policy")}
                                        className={"nav-link legal" + (this.state.legalActiveItem == "/privacy-policy" ? " selected" : "")}
                                    >
                                        {this.props.language == "ES" ? "POLÍTICA DE PRIVACIDAD" : "PRIVACY POLICY"}
                                    </Link>
                                    <Link
                                        to="/cookies-policy"
                                        onClick={() => this.onClickLegalLink("/cookies-policy")}
                                        className={"nav-link legal" + (this.state.legalActiveItem == "/cookies-policy" ? " selected" : "")}
                                    >
                                        {this.props.language == "ES" ? "POLÍTICA DE COOKIES" : "COOKIE POLICY"}
                                    </Link>
                                </>
                        }
                    </Nav>

                    <NavDropdown
                        className="language"
                        title={this.props.language == "ES" ? "Idioma" : "Language"}
                        onClick={() => {
                            if (this.props.cookieUp)
                                ReactGA4.send({ hitType: "pageview", title: "Language", page: '/Language' });
                        }}
                    >
                        <NavDropdown.Item onClick={() => this.changeLanguage("EN")}>EN <img class="imgNav uk" src={united_Kingdom} /></NavDropdown.Item>
                        <NavDropdown.Item onClick={() => this.changeLanguage("ES")}>ES <img class="imgNav" src={spainFlag} /></NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => dispatch(changeLanguage(language)),
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarWeb);