import React, { Component } from 'react';
import { Button as BootsButton, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Spinner from './Spinner';
import { connect } from "react-redux";
import {
    createTicketProspect,
    changeCreateProspectStates
} from "../actions/generalActions";
import ReactGA4 from 'react-ga4';

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            phone: "",
            comment: "",
            inquiryId: null,
            nameInputError: false,
            emailInputError: false,
            emailInputErrorType: 0,
            phoneInputError: false,
            commentInputError: false,

            isVisible: false
        }

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.checkSend = this.checkSend.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onClickInquiry = this.onClickInquiry.bind(this);

        this.changeSection = this.changeSection.bind(this);

        this.ref = React.createRef();
    }

    changeSection() {
        this.props.changeCurrentSection("Contact");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Contact", page: '/Contact' });
    }

    onChangeInput(e, input) {
        if (input == "Name" && e.target.value.length < 50) {
            const re = /^[a-zA-Z0-9 _]*$/;
            if (re.test(String(e.target.value).toLowerCase()))
                this.setState({ name: e.target.value, nameInputError: false });
        }
        else
            if (input == "Mail")
                this.setState({ email: e.target.value, emailInputError: false, emailInputErrorType: 0 });
            else
                if (input == "Comment")
                    this.setState({ comment: e.target.value, commentInputError: false });
    }

    onChangePhone(value) {
        if (value != undefined)
            this.setState({ phone: value, phoneInputError: false})
        else
            this.setState({ phone: "", phoneInputError: false})
    }

    onClickSend() {
        if(this.checkSend()){
            if (this.props.cookieUp)
                ReactGA4.gtag('event', 'click', {
                    'event_category': 'User',
                    'event_label': 'Click on Send Message',
                    'value':'contact'
                });

            this.props.createTicketProspect(
                this.state.name.trim(),
                this.state.email,
                this.state.phone,
                this.state.comment.trim(),
                this.state.inquiryId,
                document.cookie.includes("non_essential_cookies=true")
            );

            this.setState({ name: "", email: "", phone: "", comment: "", inquiryId: null });
        }
        else{
            let validPhoneNumber = isValidPhoneNumber(this.state.phone);

            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let validMail = regex.test(String(this.state.email).toLowerCase());

            this.setState({
                nameInputError: this.state.name != "" ? false : true,
                lastNameInputError: this.state.lastName != "" ? false : true,
                emailInputError: this.state.email != "" && validMail ? false : true,
                emailInputErrorType: this.state.email != "" && validMail ? 0 : (this.state.email == "" ? 0 : 1),
                phoneInputError: (this.state.phone == "" || (this.state.phone != "" && validPhoneNumber)) ? false : true,
                commentInputError: this.state.comment != "" ? false : true
            });
        }
    }

    checkSend() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let flag = re.test(String(this.state.email).toLowerCase());

        if (this.state.name !== "" && this.state.email !== "" && flag && this.state.comment !== "" && (this.state.phone == "" || (this.state.phone != "" && isValidPhoneNumber(this.state.phone))))
            return true;
        else
            return false;
    }

    onClickInquiry(inquiryId){
        this.setState({ inquiryId: inquiryId })
    }

    componentDidMount() {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    this.setState({ isVisible: entry.isIntersecting });
                });
            },
            { threshold: 0.1 }
        );
        observer.observe(this.ref.current);
    }

    render() {
        return (
            <section id="contacto" class="section-coowise" onMouseOver={this.props.currentSection != "Contact" ? this.changeSection : () => { }}>
                <Container>
                    <Row className='general-row contact'>
                        <div className='general-div'>
                            <hr class="line-title"></hr>
                            <h2 class="title-section">{this.props.language == "ES" ? "Contacto" : "Contact"}</h2>
                        </div>
                    </Row>
                    <Row className='general-row contact'>
                        <div className='general-div'>
                            <p class="paragraph-coowise">
                                {
                                    this.props.language == "ES"
                                        ? <div>Puede comunicarse con nosotros a través de <a class="mailto-coowise" href="mailto:contact@coowise.com">contact@coowise.com</a></div>
                                        : <div>Drop us an email at <a class="mailto-coowise" href="mailto:contact@coowise.com">contact@coowise.com</a></div>
                                }
                            </p>
                        </div>
                    </Row>

                    <Row className={"contact-row" + (this.state.isVisible ? " visible" : "")} ref={this.ref}>
                        <h2 class="subtitle-coowise">{this.props.language == "ES" ? "Como podemos ayudarte?" : "How can we help you?"}</h2>
                        {
                            this.props.fetchingCreateProspect == false
                                ? this.props.createProspectError == null
                                    ?
                                    <Form onSubmit={(e) => { e.preventDefault(); }}>
                                        <Form.Row className='contactus'>
                                            <Form.Group as={Col} className='badges-group'>
                                                <Button
                                                    className={"badge" + (this.state.inquiryId == 267040000 ? " active" : "")}
                                                    content={this.props.language == "ES" ? "Solicitar una llamada" : "Request a Call"}
                                                    onClick={() => this.onClickInquiry(267040000)}
                                                />
                                                <Button
                                                    className={"badge" + (this.state.inquiryId == 267040001 ? " active" : "")}
                                                    content={this.props.language == "ES" ? "Análisis" : "Assessment"}
                                                    onClick={() => this.onClickInquiry(267040001)}
                                                />
                                                <Button
                                                    className={"badge" + (this.state.inquiryId == 267040002 ? " active" : "")}
                                                    content={this.props.language == "ES" ? "Demo de Producto" : "Product Demo"}
                                                    onClick={() => this.onClickInquiry(267040002)}
                                                />
                                                <Button
                                                    className={"badge" + (this.state.inquiryId == 267040003 ? " active" : "")}
                                                    content={this.props.language == "ES" ? "Asociarte con nosotros" : "Partner with Us"}
                                                    onClick={() => this.onClickInquiry(267040003)}
                                                />
                                                <Button
                                                    className={"badge" + (this.state.inquiryId == 267040004 ? " active" : "")}
                                                    content={this.props.language == "ES" ? "Consulta General" : "General Inquiry"}
                                                    onClick={() => this.onClickInquiry(267040004)}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className='contactus'>
                                            <Form.Group as={Col}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={this.props.language == "ES" ? "Nombre/Empresa" : "Name/Company"}
                                                    value={this.state.name}
                                                    onChange={(e) => this.onChangeInput(e, "Name")}
                                                />
                                                {
                                                    this.state.nameInputError
                                                        ?
                                                        <label className="labelIncompleteData">
                                                            {
                                                                this.props.language == "ES"
                                                                    ? "Este campo no puede estar vacío."
                                                                    : "This field cannot be empty."
                                                            }
                                                        </label>
                                                        : null
                                                }
                                            </Form.Group>

                                            <Form.Group as={Col}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Email"
                                                    value={this.state.email}
                                                    onChange={(e) => this.onChangeInput(e, "Mail")}
                                                />
                                                {
                                                    this.state.emailInputError
                                                        ?
                                                        <label className="labelIncompleteData">
                                                            {
                                                                this.state.emailInputErrorType == 0
                                                                    ? this.props.language == "ES" ? "Este campo no puede estar vacío." : "The E-mail cannot be empty."
                                                                    : this.props.language == "ES" ? "El email debe ser valido." : "Your E-mail must be valid."
                                                            }
                                                        </label>
                                                        : null
                                                }
                                            </Form.Group>

                                            <Form.Group as={Col}>
                                                <div className="phoneInputDiv">
                                                    <PhoneInput
                                                        international
                                                        placeholder={this.props.language == "ES" ? "Teléfono (opcional)" : "Phone (optional)"}
                                                        value={this.state.phone}
                                                        onChange={(value) => this.onChangePhone(value)}
                                                    />
                                                </div>
                                                {
                                                    this.state.phoneInputError
                                                        ?
                                                        <label className="labelIncompleteData">
                                                            {this.props.language == "ES" ? "El telefono debe ser valido." : "Your Phone must be valid."}
                                                        </label>
                                                        : null
                                                }
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Group>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                placeholder={this.props.language == "ES" ? "Mensaje" : "Message"}
                                                value={this.state.comment}
                                                onChange={(e) => this.onChangeInput(e, "Comment")}
                                            />
                                            {
                                                this.state.commentInputError
                                                    ?
                                                    <label className="labelIncompleteData">
                                                        {
                                                            this.props.language == "ES"
                                                                ? "Este campo no puede estar vacío."
                                                                : "The Message cannot be empty."
                                                        }
                                                    </label>
                                                    : null
                                            }
                                        </Form.Group>
                                        <BootsButton
                                            className={"button-secondary-coowise"}
                                            variant="primary"
                                            onClick={() => this.onClickSend()}>
                                            {this.props.language == "ES" ? "ENVIAR MENSAJE" : "SEND MESSAGE"}
                                        </BootsButton>
                                    </Form>
                                    :
                                    <Form>
                                        <BootsButton
                                            className="button-secondary-coowise"
                                            variant="primary"
                                            onClick={this.props.changeCreateProspectStates}>
                                            {this.props.language == "ES" ? "Reintentar" : "Retry"}
                                        </BootsButton>
                                    </Form>
                                :
                                <Form>
                                    <Form.Row>
                                        <Spinner myClass="containerSpinnerSecondary" />
                                    </Form.Row>
                                </Form>
                        }
                    </Row>
                </Container>
            </section>
        );
    }
}

Contact.propTypes = {
    language: PropTypes.string.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

const mapStateToProps = (value) => {
    return {
        fetchingCreateProspect: value.general.fetchingCreateProspect,
        fetchedCreateProspect: value.general.fetchedCreateProspect,
        createProspectError: value.general.createProspectError
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createTicketProspect: (userProspectName, userProspectMail, userProspectPhone, comment, inquiryId, cookieUp) => dispatch(createTicketProspect(userProspectName, userProspectMail, userProspectPhone, comment, inquiryId, cookieUp)),
        changeCreateProspectStates: () => dispatch(changeCreateProspectStates())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);