import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import ServiceCard from './ServiceCard';

class Services extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: this.props.language,

            isVisible: false
        }

        this.changeSection = this.changeSection.bind(this);
        this.ref = React.createRef();
    }

    onClickContact() {
        let element = document.getElementById("contacto");
        if (element != null)
            element.scrollIntoView();
    }

    changeSection() {
        this.props.changeCurrentSection("Services");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Services", page: '/Services' });
    }

    componentDidMount() {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    this.setState({ isVisible: entry.isIntersecting });
                });
            },
            { threshold: 0.1 }
        );
        observer.observe(this.ref.current);
    }

    render() {
        if (this.state.language != this.props.language)
            this.setState({ language: this.props.language })

        return (
            <section id="servicios" class="section-coowise" onMouseOver={this.props.currentSection != "Services" ? this.changeSection : () => { }}>
                <Container>
                    <Row className='general-row'>
                        <div className='general-div'>
                            <hr class="line-title"></hr>
                            <h2 class="title-section">{this.props.language == "ES" ? "Servicios" : "Services"}</h2>
                        </div>
                    </Row>
                    <Row className='general-row'>
                        <div className='general-div'>
                            <p class="paragraph-coowise">
                                {
                                    this.props.language == "ES"
                                        ? "En Coowise, ofrecemos una amplia gama de servicios especializados de consultoría y desarrollo en el ámbito de cloud technology, con un enfoque en Azure, Inteligencia Artificial y Power Platform."
                                        : "At Coowise, we offer a comprehensive range of consulting and development services in the world of cloud technology, with a focus on Azure, Artificial Intelligence, and the Power Platform."
                                }
                            </p>
                            <p class="paragraph-coowise mobile">
                                {
                                    this.props.language == "ES"
                                        ? "Haz clic en cualquier servicio para aprender más!"
                                        : "Click on any service to learn more!"
                                }
                            </p>
                        </div>
                    </Row>
                    <Row className={"row-cards" + (this.state.isVisible ? " visible" : "")} ref={this.ref}>
                        <div className='sub-row-cards'>
                            <ServiceCard cardId={0} loadShortText={true} language={this.props.language}/>
                            <ServiceCard cardId={2} loadShortText={true} language={this.props.language}/>
                            
                        </div>
                        <div className='sub-row-cards'>
                            <ServiceCard cardId={1} loadShortText={true} language={this.props.language}/>
                            <ServiceCard cardId={3} loadShortText={true} language={this.props.language}/>
                        </div>
                    </Row>
                </Container>
            </section>
        );
    }
}

Services.propTypes = {
    language: PropTypes.string.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default Services;