import axios from "axios";
import * as actionTypes from "./actionTypes";

export const changeLanguage = (language) => {
    return {
        type: actionTypes.CHANGE_LANGUAGE,
        payload: {
            language: language
        }
    };
};

export const createTicketProspect = (name, mail, phone, comment, inquiryId, cookieUp) => {

    let params = {
        'ProspectName': name,
        'ProspectEmail': mail,
        'ProspectPhone': phone,
        'Comment': comment,
        'InquiryId': inquiryId,
        'CookieUp': cookieUp
    };

    return {
        type: actionTypes.FETCH_CREATETICKETPROSPECT,
        payload: axios.post("api/crm/createticketprospect", params)
    }
};

export const getWebCoowiseUrls = () => {

    return {
        type: actionTypes.FETCH_GETWEBCOOWISEURLS,
        payload: axios.get("api/generaldata/getwebcoowiseurls")
    }
};

export const changeCreateProspectStates = () => {
    return {
        type: actionTypes.CHANGE_CREATEPROSPECT_STATES
    };
};

export const changeCurrentSection = (currentSection) => {
    return {
        type: actionTypes.CHANGE_CURRENTSECTION,
        payload: {
            currentSection: currentSection
        }
    };
};

export const acceptCookies = () => {
    return {
        type: actionTypes.ACCEPT_COOKIES
    };
};