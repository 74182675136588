import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MP_White from './images/MicrosoftPartner/MP_White.png';
import MS_Badge_Dark from './images/MicrosoftPartner/MS_Badge_Dark.png';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';

class Certifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }

        this.changeSection = this.changeSection.bind(this);
        this.ref = React.createRef();
    }

    changeSection() {
        this.props.changeCurrentSection("Partnerships");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Partnerships", page: '/Partnerships' });
    }

    componentDidMount() {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    this.setState({ isVisible: entry.isIntersecting });
                });
            },
            { threshold: 0.1 }
        );
        observer.observe(this.ref.current);
    }

    render() {
        return (
            <section id="certificaciones" class="section-coowise" onMouseOver={this.props.currentSection != "Partnerships" ? this.changeSection : () => { }}>
                <Container>
                    <Row className='general-row'>
                        <div className='general-div'>
                            <hr class="line-title"></hr>
                            <h2 class="title-section">{this.props.language == "ES" ? "Asociaciones" : "Partnerships"}</h2>
                        </div>
                    </Row>
                    <Row className="row-certificaciones" ref={this.ref}>
                        <Col xs={20} lg={6} className={"col-certificaciones first" + (this.state.isVisible ? " visible" : "")}>
                            <img
                                class='first'
                                src={MP_White}
                            />
                        </Col>
                        <Col xs={20} lg={6} className={"col-certificaciones" + (this.state.isVisible ? " visible" : "")}>
                            <img
                                src={MS_Badge_Dark}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

Certifications.propTypes = {
    language: PropTypes.string.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default Certifications;