import axios from 'axios';

export const IS_DEVELOPMENT = process.env.REACT_APP_IS_DEVELOPMENT;
export const SERVER_HOSTNAME = process.env.REACT_APP_SERVER_HOSTNAME;
export const SERVER_PORT = process.env.REACT_APP_API_PORT;

export function axiosConfig() {
    axios.interceptors.request.use(async function (config) {

        if (IS_DEVELOPMENT)
            config.baseURL = `${SERVER_HOSTNAME}:${SERVER_PORT}/`;
        else
            config.baseURL = `${window.location.hostname}:${SERVER_PORT}/`;

        config.headers['Content-Type'] = 'application/json';
        config.cors = true;
        config.timeout = 10000;

        return config;
    }, function (error) {
        console.log("REJECTED request", { error });
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        /*  if (error.response.status === 401 && !originalRequest._retry) { */
        /*     originalRequest._retry = true;
            const access_token = await refreshAccessToken();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            return axiosApiInstance(originalRequest);
        } */

        return Promise.reject(error);
    });
}