import React, { Component } from 'react';
import { Route } from "react-router-dom";
import Main from './Main';
import CookiePolicy from './legal/CookiePolicy';
import PrivacyPolicy from './legal/PrivacyPolicy';

class Routes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Route exact path="/" component={Main} />
                <Route path="/cookies-policy" component={CookiePolicy} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
            </div>
        );
    }
}

export default (Routes);