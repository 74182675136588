import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloud from './images/cloud.svg';
import cloudNative from './images/cloud-native1.png';
import powerAppsLogo from './images/PowerApps_scalable.svg';
import powerPlatformSureSkills from './images/PowerPlatform_scalable.svg';
import ServiceCardModal from './modals/ServiceCardModal';

const englishTexts = [
    {
        "title": "Cloud-Native Development",
        "body": [
            {
                "title": "Microservices Architecture",
                "shortText": "Build scalable apps.",
                "longText": "Our Microservices Architecture allows you to build and scale applications more quickly and efficiently, using independently deployable services."
            },
            {
                "title": "Serverless Computing",
                "shortText": "Code without server woes.",
                "longText": "Serverless Computing lets you focus on your code, without worrying about server provisioning, maintenance, or scaling."
            }
        ]
    },
    {
        "title": "Power Platform Consulting",
        "body": [
            {
                "title": "Strategy and Roadmap",
                "shortText": "Plan your Power Platform journey.",
                "longText": "We help you develop a comprehensive strategy and roadmap for effective utilization of Power Platform services."
            },
            {
                "title": "Training and Support",
                "shortText": "Empower your team.",
                "longText": "We offer training sessions and ongoing support to ensure your team can make the most out of Power Platform."
            }
        ]
    },
    {
        "title": "Cloud Strategy and Consulting",
        "body": [
            {
                "title": "Cloud Assessment",
                "shortText": "Evaluate your cloud readiness.",
                "longText": "Our Cloud Assessment service helps you understand your current infrastructure and outlines a roadmap for cloud adoption."
            },
            {
                "title": "Cloud Migration",
                "shortText": "Seamless transition to Cloud.",
                "longText": "Our Cloud Migration service ensures a smooth and secure transition of your business data and applications to Cloud, minimizing downtime and maximizing efficiency."
            }
        ]
    },
    {
        "title": "Power Apps Development",
        "body": [
            {
                "title": "Canvas Apps",
                "shortText": "Custom apps, no-code.",
                "longText": "Build tailor-made applications without the need for extensive coding."
            },
            {
                "title": "Model-Driven Apps",
                "shortText": "Data-driven applications.",
                "longText": "Create applications that are inherently data-centric, offering deep integrations with your existing systems."
            }
        ]
    } 
];

const spanishTexts = [
    {
        "title": "Desarrollo Nativo en la Nube",
        "body": [
            {
                "title": "Arquitectura de Microservicios",
                "shortText": "Construye aplicaciones escalables.",
                "longText": "Nuestra Arquitectura de Microservicios te permite construir y escalar aplicaciones de manera más rápida y eficiente, utilizando servicios independientemente desplegables."
            },
            {
                "title": "Cómputo sin Servidor",
                "shortText": "Codifica sin preocuparte por los servidores.",
                "longText": "El Cómputo sin Servidor te permite enfocarte en tu código sin preocuparte por la provisión, el mantenimiento o la escalabilidad de los servidores."
            }
        ]
    },
    {
        "title": "Consultoría de Power Platform",
        "body": [
            {
                "title": "Estrategia y Planificación",
                "shortText": "Planifica tu viaje con Power Platform.",
                "longText": "Te ayudamos a desarrollar una estrategia completa y una hoja de ruta para la utilización efectiva de los servicios de Power Platform."
            },
            {
                "title": "Capacitación y Soporte",
                "shortText": "Capacita a tu equipo.",
                "longText": "Ofrecemos sesiones de capacitación y soporte continuo para garantizar que tu equipo pueda aprovechar al máximo Power Platform."
            }
        ]
    },
    {
        "title": "Estrategia y Consultoría en la Nube",
        "body": [
            {
                "title": "Evaluación en la Nube",
                "shortText": "Evalúa tu preparación para la nube.",
                "longText": "Nuestro servicio de Evaluación en la Nube te ayuda a comprender tu infraestructura actual y elabora un plan para la adopción de la nube."
            },
            {
                "title": "Migración a la Nube",
                "shortText": "Transición sin problemas a la Nube.",
                "longText": "Nuestro servicio de Migración a la Nube garantiza una transición suave y segura de los datos y aplicaciones de tu empresa a la Nube, minimizando el tiempo de inactividad y maximizando la eficiencia."
            }
        ]
    },
    {
        "title": "Desarrollo de Power Apps",
        "body": [
            {
                "title": "Aplicaciones Canvas",
                "shortText": "Personalizadas sin código.",
                "longText": "Construye aplicaciones a medida sin necesidad de codificación extensa."
            },
            {
                "title": "Aplicaciones Basadas en Modelos",
                "shortText": "Aplicaciones centradas en datos.",
                "longText": "Crea aplicaciones que son inherentemente centradas en datos, ofreciendo integraciones profundas con tus sistemas existentes."
            }
        ]
    }
];

const images = [cloudNative, powerPlatformSureSkills, cloud, powerAppsLogo];

class ServiceCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language: this.props.language,

            loaded: false,
            title: "",
            body: [],
            image: null,

            serviceCardModalFlag: false
        }

        this.loadCard = this.loadCard.bind(this);
        this.changeServiceCardModalFlag = this.changeServiceCardModalFlag.bind(this);
    }

    loadCard(cardId){
        this.setState({
            loaded: true, 
            title: this.state.language == "EN" ? englishTexts[cardId].title : spanishTexts[cardId].title,
            body: this.state.language == "EN" ? englishTexts[cardId].body : spanishTexts[cardId].body,
            image: images[cardId]
        });
    }

    changeServiceCardModalFlag(){
        this.setState({ serviceCardModalFlag: !this.state.serviceCardModalFlag });
    }

    render() {
        if (this.state.language != this.props.language)
            this.setState({ language: this.props.language, loaded: false });

        if(!this.state.loaded)
            this.loadCard(this.props.cardId);

        return (
            <>
                <div className='service-card' onClick={this.props.loadShortText ? this.changeServiceCardModalFlag : () => this.props.changeServiceCardModalFlag(null)}>
                    <div className='service-card-content'>
                        <div className='service-card-header'>
                            <h1 className='service-card-title'>{this.state.title}</h1>
                        </div>
                        <div className='service-card-body'>
                            {
                                this.state.body.map(subBody =>
                                    <div className='sub-body'>
                                        <h1>{subBody.title}</h1>
                                        <p className='text'>{this.props.loadShortText ? subBody.shortText : ""}</p>
                                        <p className='long-text'>{subBody.longText}</p>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className='service-card-image'>
                        <img className={this.props.cardId == 2 ? "cloud" : ""} src={this.state.image}/>
                    </div>
                </div>
                <ServiceCardModal 
                    serviceCardModalFlag={this.state.serviceCardModalFlag} 
                    changeServiceCardModalFlag={this.changeServiceCardModalFlag} 
                    cardId={this.props.cardId}
                    language={this.props.language}
                />
            </>
        );
    }
}

ServiceCard.propTypes = {
    language: PropTypes.string.isRequired,
    cardId: PropTypes.number.isRequired,
    loadShortText: PropTypes.bool.isRequired,
    changeServiceCardModalFlag: PropTypes.func.isRequired
};

export default (ServiceCard);