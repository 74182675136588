import React, { Component } from 'react';
import './App.scss'
import NavbarWeb from './components/NavbarWeb';
import Spinner from './components/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from "react-redux";
import {
  getWebCoowiseUrls
} from "./actions/generalActions";
import ReactGA4 from 'react-ga4';
import { BrowserRouter } from "react-router-dom";
import Routes from './components/Routes';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (window.location.pathname != "/" && window.location.pathname != "/cookies-policy" && window.location.pathname != "/privacy-policy")
      window.location.pathname = "/";

    if (this.props.fetchingGetWebCoowiseUrls == false && this.props.getWebCoowiseUrlsDTO == null && this.props.getWebCoowiseUrlsError == null)
      this.props.getWebCoowiseUrls();

    if (((this.props.fetchedGetWebCoowiseUrls == true && this.props.getWebCoowiseUrlsDTO != null) || this.props.getWebCoowiseUrlsError != null)) {

      if (this.props.cookieUp) {
        ReactGA4.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
        ReactGA4.send({ hitType: "pageview", title: "Main", page: '/Main' });
      }

      return (
        <div className="App">
          <BrowserRouter>
            <NavbarWeb />
            <Routes />
          </BrowserRouter>
        </div>
      );
    }
    else
      return (
        <Spinner myClass="containerSpinnerGeneral" />
      );
  }
}

const mapStateToProps = (value) => {
  return {
    fetchingGetWebCoowiseUrls: value.general.fetchingGetWebCoowiseUrls,
    fetchedGetWebCoowiseUrls: value.general.fetchedGetWebCoowiseUrls,
    getWebCoowiseUrlsError: value.general.getWebCoowiseUrlsError,
    getWebCoowiseUrlsDTO: value.general.getWebCoowiseUrlsDTO,

    cookieUp: value.general.cookieUp
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWebCoowiseUrls: () => dispatch(getWebCoowiseUrls())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);