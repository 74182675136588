import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react'

class Spinner extends Component {
    render() {
        return (
            <div className={this.props.myClass}>
                <Dimmer active>
                    <Loader>Loading</Loader>
                </Dimmer>
            </div>
        );
    }
}

export default Spinner;