import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../actions/generalActions";

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Privacy-Policy");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Privacy-Policy", page: '/Privacy-Policy' });
    }

    render() {
        return (
            <section id="privacy-section" class="privacy-section" onMouseOver={this.props.currentSection != "Privacy-Policy" ? this.changeSection : () => { }}>
                <Container>
                    <Row>
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h1 className="title-cookies-policy">{this.props.language == "ES" ? "Política de Privacidad" : "Privacy Policy"}</h1>
                            <p className='text-below-title'>
                                {this.props.language == "ES" ? "Actualizado el 25 de Marzo de 2024" : "Updated on Mar 25th, 2024"}
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">
                                {this.props.language == "ES" ? "1. Responsable del tratamiento de datos y descripción" : "1. Data controller and description"}
                            </h2>
                            <Col md="auto" className='sub-columns privacy'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{this.props.language == "ES" ? "1.1. Parte responsable" : "1.1. Responsible party"}</h2>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Nombre Legal de la empresa: CoowiseIT OÜ"
                                            : "Company Legal Name: CoowiseIT OÜ"
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Número de registro: 16129859"
                                            : "Registration No: 16129859"
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Número VAT: EE102700114"
                                            : "VAT Number: EE102700114"
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {"E-mail: "}
                                    <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{this.props.language == "ES" ? "1.2. Descripción" : "1.2. Description"}</h2>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "La aceptación de la política de privacidad de Coowise (en adelante denominada \"Política de Privacidad\") " + 
                                              "es una condición necesaria para el uso de nuestro sitio web y la prestación del servicio (en adelante el \"Servicio\")."
                                            : "The acceptance of Coowise privacy policy (hereinafter referred to as \"Privacy Policy\") " + 
                                              "is a necessary condition for the use of our website and the provision of the service (hereinafter the \"Service\")."
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Esta Política de Privacidad regula la recopilación, procesamiento y uso de su información personal y no personal " + 
                                              "como usuario de Coowise, a partir de la fecha efectiva que se muestra en el encabezado."
                                            : "This Privacy Policy governs the collection, processing and use of your personal and non-personal information " + 
                                              "as a user of Coowise, as of the effective date shown in the header."
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Al procesar sus datos personales, Coowise cumple con todas las leyes y regulaciones locales y europeas aplicables."
                                            : "In processing your personal data, Coowise complies with all applicable local and European laws and regulations."
                                    }
                                </p>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">
                                {this.props.language == "ES" ? "2. Información recopilada" : "2. Information collected"}
                            </h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "El Servicio puede ser utilizado sin proporcionar ninguna información, aunque para solicitar información " + 
                                          "o contratar nuestros servicios deberá proporcionarnos cierta información personal. " + 
                                          "La información (personal o no personal) recopilada por el Servicio puede variar en consecuencia."
                                        : "The Service can be used without providing any information, although in order to request information or " + 
                                          "contract our services you will need to provide us with some personal information. " + 
                                          "The information (personal or non-personal) collected by the Service may vary accordingly."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "La información personal y no personal recopilada por el Servicio nos llegará de tres formas: 1) la que se recopila automáticamente; 2) la que usted nos proporciona voluntariamente; y 3) la que es proporcionada por terceros."
                                        : "The personal and non-personal information collected by the Service will come to us in three ways: 1) that which is collected automatically; 2) that which you voluntarily provide to us; and 3) that which is provided by third parties."
                                }
                            </p>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{this.props.language == "ES" ? "2.1. Recopilación automática" : "2.1. Automatically collected"}</h2>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Esta información consistirá en:"
                                            : "This information will consist of:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? <>Información recopilada a través de cookies u otros mecanismos similares almacenados en su dispositivo, siempre con su consentimiento o de acuerdo con otras bases legales. Consulte nuestra <a href='/cookies-policy'>Política de Cookies</a> para obtener más información.</>
                                                : <>Information collected through cookies or similar mechanisms stored on your device, always with your consent or in accordance with other legal bases. See our <a href='/cookies-policy'>Cookie Policy</a> for more information.</>
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "La dirección IP desde la cual se realiza la conexión, el tipo de dispositivo utilizado y sus características, la versión del sistema operativo, el tipo de navegador, el idioma, la fecha, el país, la hora de la solicitud, la URL desde la cual proviene, la URL a la que se dirige o la red móvil utilizada, entre otros."
                                                : "The IP from which the connection is made, the type of device used and its characteristics, the version of the operating system, the type of browser, the language, the date, the country, the time of the request, the URL from which you came, the URL to which you go or the mobile network used, among others."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Datos sobre el uso del Servicio y posibles errores detectados durante su uso."
                                                : "Data on the use of the Service and possible errors detected during its use."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? <>Además, Coowise utiliza Google Analytics 4, un servicio de análisis proporcionado por Google, LLC,  
                                                   con sede en los Estados Unidos en 1600 Amphitheatre Parkway, Mountain View, California 94043. 
                                                   Para proporcionar estos servicios, utilizan cookies que recopilan información, incluida la dirección IP 
                                                   del usuario, que será transmitida, procesada y almacenada por Google según los términos establecidos en el 
                                                   sitio web <a href='www.google.com'>www.google.com</a>. Esto incluye la posible transmisión de dicha información a terceros por motivos de 
                                                   requerimiento legal o cuando dichos terceros procesen la información en nombre de Google.</>
                                                : <>In addition, Coowise uses Google Analytics 4, an analytics service provided by Google, 
                                                   LLC. located in the United States with headquarters at 1600 Amphitheatre Parkway, Mountain View, California 94043. 
                                                   To provide these services, they use cookies that collect information, including the user's IP address,  
                                                   which will be transmitted, processed and stored by Google on the terms set out on the 
                                                   website <a href='www.google.com'>www.google.com</a>. Including the possible transmission of such information to third parties  
                                                   for reasons of legal requirement or where such third parties process the information on Google's behalf.</>
                                        }
                                    </li>
                                </ul>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "En cualquier caso, puede desactivar las cookies de Google Analytics 4 desde "
                                            : "In any case, you can disable Google Analytics 4 cookies from "
                                    }
                                    <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">{this.props.language == "ES" ? "aquí" : "here"}</a>.
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">
                                    {this.props.language == "ES" ? "2.2. Información que proporciona voluntariamente" : "2.2. Information you provide voluntarily"}
                                </h2>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Esta información consistirá en:"
                                            : "This information will consist of:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "La información requerida por Coowise para completar su registro en el sitio web: nombre y apellido, " + 
                                                  "dirección de correo electrónico, número de teléfono, nombre de la empresa, nivel de experiencia en licitaciones " + 
                                                  "del sector público, región y sector empresarial, entre otros."
                                                : "The information required by Coowise to complete your registration on the website: name and surname, " + 
                                                  "email address, telephone number, company name, level of experience in public sector tenders, " + 
                                                  "region and business sector, among others."
                                        }
                                    </li>
                                </ul>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Todos los datos son obligatorios."
                                            : "All data is mandatory."
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Si está registrando en nombre de otra persona a través del Servicio, garantiza que ha obtenido legalmente la " + 
                                              "información personal y el consentimiento de dichas personas antes de proporcionarnos sus datos."
                                            : "If you are registering on behalf of another person through the Service, you warrant that you have lawfully " + 
                                              "obtained the personal information and consent of such other persons before providing us with their data."
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "En relación a tu contraseña, como usuario del Servicio eres responsable de su protección. En este sentido, " + 
                                              "asumirás cualquier daño y perjuicio que pueda resultar de su uso indebido o de la transferencia de la misma a terceros."
                                            : "In relation to your password, as a user of the Service you are responsible for its protection. " + 
                                              "In this sense, you will assume any damage and harm that may result from its improper use or the transfer " + 
                                              "of the same to third parties."
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "La información, personal u otra, que pueda estar contenida en los mensajes enviados a través de los " + 
                                                  "canales de contacto u opinión establecidos en el Servicio, por ejemplo, tu nombre, dirección de correo " + 
                                                  "electrónico, número de teléfono y mensaje."
                                                : "The information, personal or otherwise, that may be contained in the messages sent through the contact or " + 
                                                  "opinion channels established in the Service, for example your name, email address, telephone number and message."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Información, personal u otra, que proporcionas para hacer uso de la prueba gratuita, por ejemplo, nombre, " + 
                                                  "dirección de correo electrónico, número de teléfono, nombre de la empresa, sector en el que opera la empresa " + 
                                                  "y ubicación de la misma."
                                                : "Information, personal or otherwise, that you provide to make use of the free trial, e.g. name, email address, " + 
                                                  "telephone number, company name, industry and where the company operates."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "La información, personal u otra, que proporcionas para comprar una suscripción, por ejemplo, nombre y apellido, " + 
                                                  "dirección de correo electrónico, número de teléfono, nombre de la empresa, sector en el que opera la empresa, " + 
                                                  "ubicación de la misma y detalles de pago."
                                                : "The information, personal or otherwise, that you provide to purchase a subscription, e.g. first and last name, " + 
                                                  "email address, telephone number, company name, industry, where the company operates and payment details."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Información personal requerida para suscribirse al boletín informativo, como tu dirección de correo electrónico."
                                                : "Personal information required to subscribe to the newsletter, such as your email address."
                                        }
                                    </li>
                                </ul>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">
                                    {this.props.language == "ES" ? "2.3. Información proporcionada por terceros" : "2.3. Information provided by third parties"}
                                </h2>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Esta información consistirá en:"
                                            : "This information will consist of:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Información proporcionada por sistemas de pago o procesadores de tarjetas de crédito, " + 
                                                  "como la hora de compra o el monto de la compra."
                                                : "Information provided by payment systems or credit card processors, " + 
                                                  "such as the time of purchase or the amount of the purchase."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Información proporcionada por redes sociales u servicios similares."
                                                : "Information provided by social networks or similar services."
                                        }
                                    </li>
                                </ul>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">
                                {this.props.language == "ES" ? "3. Derechos y propósitos" : "3. Rights and purposes"}
                            </h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Le informamos que el llenado de los formularios es voluntario. Sin embargo, si no completa los campos obligatorios " + 
                                          "(generalmente marcados con un asterisco), no será posible o estará limitado el uso de algunas funciones del Servicio."
                                        : "We inform you that the completion of the forms is voluntary. However, if you do not fill in the mandatory fields " + 
                                          "(usually marked with an asterisk), the use of some functions of the Service will not be possible or will be limited."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Los datos personales que proporciones serán almacenados y procesados en el Registro de Actividades de Tratamiento de " + 
                                          "Coowise con el fin de atender tus solicitudes, proporcionar el servicio solicitado y mantenerte informado/a sobre asuntos " + 
                                          "relacionados con la actividad de la empresa y sus servicios."
                                        : "The personal data you provide will be stored and processed in Coowise's Register of Processing Activities in order " + 
                                          "to deal with your requests, provide the requested service and keep you informed about matters relating to the company's " + 
                                          "activity and its services."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "En cualquier momento, puedes ejercer tus derechos de acceso, rectificación, supresión, limitación, oposición y " + 
                                          "portabilidad de tus datos personales enviando un correo electrónico a: "
                                        : "You may at any time exercise your rights of access, rectification, erasure, restriction, objection and portability " + 
                                          "of your personal data by sending an email to: "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                                {
                                    this.props.language == "ES"
                                        ? " o a la dirección postal: Pärnu mnt 139c, Tallinn, Estonia, 11317."
                                        : " or to the postal address: Pärnu mnt 139c, Tallinn, Estonia, 11317."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "En ambos casos, debes identificarte con tu nombre y apellidos y adjuntar una copia de tu tarjeta de identificación " + 
                                          "o documento nacional de identidad, si es necesario."
                                        : "In both cases you must identify yourself with your first and last name and a copy of your ID " + 
                                          "card or national ID card, if necessary."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Si has dado tu consentimiento para un propósito específico, tienes derecho a retirar tu consentimiento en cualquier " + 
                                          "momento, sin que ello afecte a la legalidad del tratamiento basado en el consentimiento previo a su retirada."
                                        : "If you have given consent for a specific purpose, you have the right to withdraw your consent at any time, " + 
                                          "without affecting the lawfulness of the processing based on the consent prior to its withdrawal."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Además, si consideras que hay un problema con la forma en que Coowise está gestionando tus datos, puedes dirigir tus quejas a la autoridad de "
                                        : "In addition, if you feel that there is a problem with the way Coowise is handling your data, you can address your complaints to the "
                                }
                                <a href="https://www.aepd.es/reglamento/derechos/index.html" target="_blank">
                                    {this.props.language == "ES" ? "protección de datos correspondiente" : "appropriate data protection"}
                                </a>
                                {
                                    this.props.language == "ES"
                                        ? ", siendo la "
                                        : " authority, being the "
                                }
                                <a href="https://www.agpd.es/" target="_blank">
                                    {this.props.language == "ES" ? "Agencia Española de Protección de Datos" : "Spanish Data Protection Agency"}
                                </a>
                                {
                                    this.props.language == "ES"
                                        ? " la indicada en el caso de España."
                                        : " the one indicated in the case of Spain."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">
                                {this.props.language == "ES" ? "4. Edad" : "4. Age"}
                            </h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "En relación a tu uso del servicio en línea, declara que eres mayor de edad y que tienes la capacidad legal necesaria para estar vinculado por este acuerdo y utilizar el sitio de acuerdo con sus términos y condiciones, los cuales entiendes y reconoces plenamente."
                                        : "With respect to your use of the online service, you declare that you are of legal age and that you have the necessary legal capacity to be bound by this agreement and to use the site in accordance with its terms and conditions, which you fully understand and acknowledge."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Declara que toda la información que proporcionas para acceder al Servicio, antes y durante su uso, es veraz, completa y precisa."
                                        : "You declare that all the information you provide to access the Service, before and during its use, is true, complete and accurate."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">{this.props.language == "ES" ? "5. Uso de los datos" : "5. Use of data"}</h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Coowise utilizará los datos recopilados para:"
                                        : "Coowise will use the data collected to:"
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Administrar y actualizar el Servicio (la base legal siendo nuestro interés legítimo en mantener y actualizar el Servicio en buen estado)."
                                            : "Administer and update the Service (the legal basis being our legitimate interest in keeping and maintaining the Service up to date and in good order)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Atender los asuntos que planteas (la base legal siendo nuestro interés legítimo en responder a las solicitudes y consultas de nuestros usuarios)."
                                            : "To deal with the issues you raise (the legal basis being our legitimate interest in dealing with our users' requests and queries)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Gestionar tu registro en la plataforma (la base legal siendo tu consentimiento)."
                                            : "Manage your registration on the platform (the legal basis being your consent)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Proporcionar el servicio contratado (la base legal siendo el contrato)."
                                            : "Provide the contracted service (the legal basis being the contract)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Procesar los pagos que realices y emitir las correspondientes facturas (la base legal siendo el contrato)."
                                            : "Process the payments you make and issue the corresponding invoices (the legal basis being the contract)."
                                    }
                                </li>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Enviarte por correo electrónico nuestro boletín con ofertas promocionales relacionadas con el servicio contratado (siendo la base legal, según el caso, la relación contractual o nuestro interés legítimo para acciones de marketing directo basadas en la relación contractual previa, de acuerdo con el artículo 21.2 de la LSSICE)."
                                            : "Send you by email our newsletter with promotional offers related to the contracted service (being the legal basis, depending on the case, the contractual relationship or our legitimate interest for direct marketing actions based on the previous contractual relationship, according to art. 21.2 LSSICE)."
                                    }
                                </li>
                            </ul>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Podrás darte de baja de los mismos desde el correo electrónico que recibas o contactándonos en "
                                        : "You will be able to unsubscribe from them from the email you receive or by contacting us at "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                                {
                                    this.props.language == "ES"
                                        ? ". Sin embargo, no podrás darte de baja de ciertas comunicaciones de nuestra parte, como mensajes relacionados con la seguridad de tus datos o los términos y condiciones del Servicio."
                                        : ". However, you will not be able to unsubscribe from certain correspondence from us, such as messages relating to the security of your data or the terms and conditions of the Service."
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Para mantener la seguridad del Servicio, investigar actividades ilegales, hacer cumplir nuestros términos y condiciones y colaborar con las autoridades encargadas de hacer cumplir la ley en sus investigaciones (la base legal siendo nuestro interés legítimo en garantizar y mantener la seguridad del Servicio y sus usuarios)."
                                            : "To maintain the security of the Service, to investigate illegal activity, to enforce our terms and conditions and to assist law enforcement agencies in their investigations (the legal basis being our legitimate interest in ensuring and maintaining the security of the Service and its users)."
                                    }
                                </li>
                            </ul>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Coowise no utiliza decisiones automatizadas, aunque puede generar perfiles básicos de usuarios, siendo la base legal nuestro interés legítimo con fines comerciales y para ofrecer ofertas personalizadas."
                                        : "Coowise does not use automated decisions, although it may generate basic profiles of users, the legal basis being our legitimate interest for commercial purposes and to provide personalised offers."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Coowise también puede utilizar la información de los usuarios en forma de datos agregados y anónimos para mostrar a terceros. También podemos compartir estadísticas e información demográfica sobre los usuarios y su uso del Servicio con terceros. Sin embargo, nada de esto permitirá a esos terceros identificarte personalmente."
                                        : "Coowise may also use user information in the form of aggregated, anonymous data for display to third parties. We may also share statistics and demographic information about users and their use of the Service with third parties. But none of this will allow those third parties to identify you personally."
                                }
                            </p>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">
                                    {this.props.language == "ES" ? "5.1. En correos electrónicos y formularios de contacto" : "5.1. In mails and contact forms"}
                                </h2>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "El sitio web del Servicio cuenta con un cifrado TLS que garantiza que el usuario pueda enviar de forma segura sus datos personales a través de los formularios de contacto estándar."
                                            : "The Service's website has a TLS encryption that ensures that the user can safely send your personal data through standard contact forms."
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Los datos personales recopilados serán procesados automáticamente e incorporados a los archivos correspondientes del registro de actividades de tratamiento, de los cuales Coowise es el titular."
                                            : "Your personal data collected will be automatically processed and incorporated into the corresponding files of the register of processing activities and of which Coowise is the owner."
                                    }
                                </p>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "En este sentido:"
                                            : "In this regard:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Recibiremos tu dirección IP, la cual será utilizada para verificar el origen del mensaje con el fin de proporcionarte recomendaciones adecuadas (por ejemplo, presentar la información en el idioma correcto) y detectar posibles irregularidades (por ejemplo, posibles intentos de ciberataque al Servicio), así como datos relacionados con tu proveedor de servicios de internet (ISP)."
                                                : "We will receive your IP, which will be used to check the origin of the message in order to provide you with appropriate recommendations (e.g. to present the information in the correct language) and to detect possible irregularities (e.g. possible attempts to cyber-attack the Service), as well as data relating to your ISP."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "De igual manera, puedes proporcionarnos tus datos por teléfono, correo electrónico y otros medios de comunicación indicados."
                                                : "Likewise, you can provide us with your data by phone, email and other indicated means of communication."
                                        }
                                    </li>
                                </ul>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? 
                                            "En cuanto a los medios de pago utilizados:"
                                            : "Regarding the means of payment used:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Nuestros proveedores de servicios de pago para pagar por la prestación de nuestros servicios son tarjetas de crédito o débito a través de una pasarela de pago segura (Stripe). En ningún momento el Servicio ni su personal tendrán acceso a los detalles bancarios (por ejemplo, número de tarjeta de crédito) que usted como cliente proporciona a estos terceros."
                                                : "Our payment service providers to pay for the provision of our services are credit or debit card through a safely payment gateway (Stripe). At no time will the Service or its staff have access to the bank details (e.g. credit card number) that you as a customer provide to these third parties."
                                        }
                                    </li>
                                </ul>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">{this.props.language == "ES" ? "6. Retención de datos" : "6. Data retention"}</h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "A continuación, se indica durante cuánto tiempo se conservan los datos procesados por el Servicio:"
                                        : "The following is an indication of how long the data processed by the Service is retained:"
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Los datos desagregados se conservarán sin un período de eliminación."
                                            : "Disaggregated data will be retained with no deletion period."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Los datos del cliente se conservarán durante el período mínimo necesario, que puede ser de hasta:"
                                            : "Customer data will be kept for the minimum necessary period of time, which may be up to:"
                                    }
                                </li>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "5 años, de acuerdo con el artículo 1964 del Código Civil (acciones personales sin plazo especial)."
                                                : "5 years, according to art. 1964 of the Civil Code (personal actions without special time limit)."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "6 años, según el Artículo 30 del Código de Comercio (por ejemplo, libros contables o facturas)."
                                                : "6 years, according to Art. 30 of the Commercial Code (e.g. accounting books or invoices)."
                                        }
                                    </li>
                                </ul>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Los datos de los usuarios se conservarán como máximo durante 12 meses para poder responder a sus solicitudes."
                                            : "Users data will be kept for a maximum of 12 months in order to be able to respond to their requests."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Los datos de los suscriptores del boletín se conservarán desde el momento en que el usuario se suscribe hasta que el usuario se dé de baja."
                                            : "The data of newsletter subscribers will be kept from the time the user subscribes until the user unsubscribes."
                                    }
                                </li>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Los datos de los usuarios cargados por Coowise en sus redes sociales se conservarán desde el momento en que el usuario otorga su consentimiento hasta que lo retire."
                                            : "The data of users uploaded by Coowise to its social networks will be kept from the time the user gives their consent until they withdraw it."
                                    }
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">
                                {this.props.language == "ES" ? "7. Datos compartidos con terceros" : "7. Data shared with third parties"}
                            </h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Hay terceros que gestionan parte del Servicio."
                                        : "There are third parties that manage part of the Service."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Coowise les exige que cumplan con esta Política de Privacidad en la medida en que les sea aplicable. Sin embargo, Coowise no será responsable de su cumplimiento con esta política."
                                        : "Coowise requires them to comply with this Privacy Policy as far as it applies to them. However, Coowise will not be responsible for their compliance with this policy."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Bajo algunas circunstancias, Coowise puede compartir, utilizar o conservar con terceros parte de la información personal recopilada:"
                                        : "Under some circumstances, Coowise may share, use or preserve with third parties some of the personal information collected:"
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Para proporcionar el Servicio:"
                                            : "To provide the Service:"
                                    }
                                </li>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Proveedores de servicios que realizan funciones en nuestro nombre, como servicios de pago, alojamiento web, análisis empresarial, herramientas de CRM, envío de correo electrónico y facturación, servicio de atención al cliente, marketing web o la agencia encargada de los datos de facturación. Estos proveedores de servicios pueden recopilar y acceder a la información necesaria para realizar sus funciones, pero no se les permite compartir o utilizar la información para ningún otro propósito."
                                            : "Service providers that perform functions on our behalf, such as payment services, web hosting, business analytics, CRM mailing and billing tool, customer service, web marketing, or the agency that handles billing data. These service providers may collect and have access to information that is necessary for them to perform their functions, but they are not allowed to share or use the information for any other purpose."
                                    }
                                </p>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Para cooperar con las autoridades encargadas de hacer cumplir la ley:"
                                            : "To cooperate with law enforcement authorities:"
                                    }
                                </li>
                                <p className='privacy-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Si creemos que es razonablemente necesario para cumplir con cualquier ley, proceso legal o interés legítimo. En cualquier caso, solo proporcionaremos la información que sea estrictamente requerida."
                                            : "If we believe it is reasonably necessary to satisfy any law, legal process or legitimate interest. In any event, we will only provide information that is strictly required."
                                    }
                                </p>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">{this.props.language == "ES" ? "8. Medidas de seguridad" : "8. Security Measures"}</h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Coowise toma todas las medidas técnicas y organizativas necesarias para proteger la seguridad e integridad de la información personal y no personal recopilada. Tanto contra el acceso no autorizado como contra la alteración, pérdida o destrucción accidental."
                                        : "Coowise takes all the necessary technical and organisational measures to protect the security and integrity of personal and non-personal information collected. Both against unauthorised access and against accidental alteration, loss or destruction."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "En cualquier caso, Coowise no puede garantizar la seguridad absoluta de la información recopilada, por lo que debes colaborar y utilizar el sentido común en relación a la información compartida en todo momento."
                                        : "In any case, Coowise cannot guarantee the absolute security of the information collected, so you must cooperate and use common sense about the information shared at all times."
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Comprendes y reconoces que, incluso después de la eliminación, la información personal y no personal puede permanecer visible en caché o si otros usuarios la han copiado o almacenado."
                                        : "You understand and acknowledge that, even after deletion, personal and non-personal information may remain visible in cache or if other users have copied or stored it."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">
                                {this.props.language == "ES" ? "9. Cambios en la Política de Privacidad" : "9. Changes in the Privacy Policy"}
                            </h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Podemos actualizar esta Política de Privacidad en el futuro. Te notificaremos los cambios enviándote un aviso a la dirección de correo electrónico proporcionada y/o publicando un aviso destacado en nuestro sitio web."
                                        : "We may update this Privacy Policy in the future. We will notify you of changes by sending a notice to the email address provided and/or by posting a prominent notice on our website."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-privacy-policy">{this.props.language == "ES" ? "10. Contacto" : "10. Contact"}</h2>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Si tienes alguna pregunta sobre esta Política de Privacidad, por favor contáctanos en:"
                                        : "If you have any questions about this Privacy Policy, please contact us at:"
                                }
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "E-mail: "
                                        : "E-mail: "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                            </p>
                            <p className='privacy-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Dirección: Pärnu mnt 139c, Tallinn, Estonia, 11317."
                                        : "Address: Pärnu mnt 139c, Tallinn, Estonia, 11317."
                                }
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        language: value.general.language,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);