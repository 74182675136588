import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ServiceCard from '../ServiceCard';
import skip from '../images/skip.svg';

class ServiceCardModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactModal 
                onRequestClose={() => this.props.changeServiceCardModalFlag(null)} 
                shouldCloseOnOverlayClick={true} 
                isOpen={this.props.serviceCardModalFlag} 
                className={"generic-modal container-modal animate__animated animate__fadeInDown animate__faster"}
            >
                    <img className= "closeModal" src={skip} onClick={() => this.props.changeServiceCardModalFlag(null)}/>
                <ServiceCard 
                    cardId={this.props.cardId} 
                    loadShortText={false} 
                    changeServiceCardModalFlag={this.props.changeServiceCardModalFlag}
                    language={this.props.language}
                />
            </ReactModal>
        );
    }
}

ServiceCardModal.propTypes = {
    serviceCardModalFlag: PropTypes.bool.isRequired,
    changeServiceCardModalFlag: PropTypes.func.isRequired,
    cardId: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired
};

export default (ServiceCardModal);