import * as actionTypes from '../actions/actionTypes';
import ReactGA4 from 'react-ga4';

const initialState = {

    //Change Language

    language: "EN",

    //Create Prospect

    fetchingCreateProspect: false,
    fetchedCreateProspect: false,
    createProspectError: null,

    //Get webcoowise urls

    fetchingGetWebCoowiseUrls: false,
    fetchedGetWebCoowiseUrls: true,
    getWebCoowiseUrlsError: null,
    getWebCoowiseUrlsDTO: {"webTendersUrl": process.env.REACT_APP_TENDERS_WEB_URL},

    //Current Section

    currentSection: "Home",

    //Accept cookies

    cookieUp: document.cookie.includes("essential_cookies=true")
}

export default (state = initialState, action) => {

    switch (action.type) {

        //Change Language

        case actionTypes.CHANGE_LANGUAGE: {
            return {
                ...state,
                language: action.payload.language
            }
            break;
        }

        //Create Prospect

        case actionTypes.FETCH_CREATETICKETPROSPECT + "_PENDING": {

            return {
                ...state,
                fetchingCreateProspect: true,
                fetchedCreateProspect: false,
                createProspectError: null
            }
            break;
        }
        case actionTypes.FETCH_CREATETICKETPROSPECT + "_REJECTED": {

            if (state.cookieUp)
                ReactGA4.gtag('event', 'click', {
                    'event_category': 'User',
                    'event_label': 'Message not sent',
                    'value': 'Message not sent'
                });

            let error = null;
            if (action.payload.response == undefined)
                error = action.payload.message;
            else
                if (action.payload.response.data == undefined)
                    error = action.payload.message;
                else
                    error = action.payload.response.data;

            return {
                ...state,
                fetchingCreateProspect: false,
                createProspectError: error
            }
            break;
        }
        case actionTypes.FETCH_CREATETICKETPROSPECT + "_FULFILLED": {

            if (state.cookieUp)
                ReactGA4.gtag('event', 'click', {
                    'event_category': 'User',
                    'event_label': 'Message sent',
                    'value': 'Message sent'
                });

            return {
                ...state,
                fetchingCreateProspect: false,
                fetchedCreateProspect: true
            }
            break;
        }

        //Get webcoowise urls

        case actionTypes.FETCH_GETWEBCOOWISEURLS + "_PENDING": {
            return {
                ...state,
                fetchingGetWebCoowiseUrls: true,
                fetchedGetWebCoowiseUrls: false
            }
            break;
        }
        case actionTypes.FETCH_GETWEBCOOWISEURLS + "_REJECTED": {
            let error = null;
            if (action.payload.response == undefined)
                error = action.payload.message;
            else
                if (action.payload.response.data == undefined)
                    error = action.payload.message;
                else
                    error = action.payload.response.data;

            return {
                ...state,
                fetchingGetWebCoowiseUrls: false,
                getWebCoowiseUrlsError: error
            }
            break;
        }
        case actionTypes.FETCH_GETWEBCOOWISEURLS + "_FULFILLED": {

            return {
                ...state,
                fetchingGetWebCoowiseUrls: false,
                fetchedGetWebCoowiseUrls: true,
                getWebCoowiseUrlsDTO: action.payload.data
            }
            break;
        }

        //Retry Create Prospect

        case actionTypes.CHANGE_CREATEPROSPECT_STATES: {
            return {
                ...state,
                createProspectError: null,
                fetchedCreateProspect: false
            }
            break;
        }

        //Change Current Section

        case actionTypes.CHANGE_CURRENTSECTION: {
            return {
                ...state,
                currentSection: action.payload.currentSection
            }
            break;
        }

        //Accept cookies

        case actionTypes.ACCEPT_COOKIES: {
            return {
                ...state,
                cookieUp: true
            }
            break;
        }

    }
    return state;
}