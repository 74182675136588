import {combineReducers} from 'redux';

import generalReducer from './generalReducer';

const allReducers = combineReducers({
    general: generalReducer
});

const rootReducer = (state, action) => {
    return allReducers(state, action)
};

export default rootReducer;